<template>
<v-col cols="12" sm="11" md="10" lg="7" style="padding-bottom:200px;" class="mx-auto" v-if="$store.state.auth.user._id">
  <v-card color="transparent" elevation="0">
    <v-col cols="12" class="text-center">
      
      <v-gravatar height="90" style="border-radius:50%;" class="mt-3"
                  :default-img="'retro'" :email="$store.state.auth.user.name"/>
      <br>
      <v-card-title class="pa-2">{{ $store.state.auth.user.name }}</v-card-title>
      <br>
      <br>
      <v-card-title class="pa-2">
        <v-icon>mdi-map-marker</v-icon>
        Enregistrer votre position principale
      </v-card-title>
      <br>

    </v-col>

    <v-divider class="mt-0 mb-3"></v-divider>


    <v-row>
      
      <v-col cols="12" md="6" class="mt-5 px-5">
        <FormAddress></FormAddress>
        <v-row class="d-block pt-4 mt-5">
          <v-divider class="mb-3" />
          <v-btn block color="blue" class=" mt-5" rounded to="/map">
            <v-icon class="mr-1">mdi-arrow-left-circle</v-icon> Retourner sur la carte
          </v-btn>
        </v-row>
      </v-col>

      <v-col cols="12" md="6" class="">
        <v-card-text class="pt-4">
          - Devenez visible aux yeux des autres pirates sur la carte principale.<br><br>
          - Accélérez vos prochaines recherches.<br><br>
          - Localisez plus rapidement vos prochaines annonces.<br><br>
          <v-icon small>mdi-check</v-icon> Vous êtes libre de vous localiser où vous le souhaitez.
        </v-card-text>
      </v-col>
    
    </v-row>
  </v-card>

</v-col>
</template>


<script>

import FormAddress from '@/components/profil/FormAddress.vue'

import Gravatar from 'vue-gravatar'
// import { i18n } from 'vue-lang-router';
import core from '../../plugins/core.js'

export default {
  name: 'home',
  components: {
    'v-gravatar' : Gravatar, FormAddress,
  },
  data: () => ({
    
  }),
  mounted: async function(){
    this.$store.dispatch('app/incStat', '/step/geoloc')
    this.enableMailNotif = this.$store.state.auth.user.enableMailNotif
  },
  methods: {
    async initData(){
    },
    baseUrl(){ return core.baseUrl() },
    offerText(text){
      let txt = text.substr(0, 60)
      return txt + (text.length > 60 ? "..." : "")
    },
    nl2br: function(str){
      var breakTag = '<br>'
      let newStr = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
      let maxLength = 60
      let p = newStr.length > maxLength ? '...' : ''
      newStr = newStr.substr(0, maxLength) + p
      return newStr
    },
    
  },
  computed: {
  },
  watch: {
      
  }, 
}
</script>